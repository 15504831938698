import './App.css'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Navbar from 'react-bootstrap/Navbar'
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function CustomizedAxisTick ({x, y, payload}) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666"
            transform="rotate(-35)">{DateTime.fromSQL(payload.value).toFormat('dd.MM. HH:mm')}</text>
    </g>
  )
}

const TimeRanges = Object.freeze({
  LAST_DAY: 1,
  LAST_SEVEN_DAYS: 7,
  LAST_MONTH: 30
})

function App () {
  const [currentRange, setCurrentRange] = useState(TimeRanges.LAST_DAY)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    const controller = new AbortController()
    fetch('https://node-red.andicodes.de/view-kartoffellager?days=' + currentRange,
        {
          signal: controller.signal
        })
      .then(res => res.json())
      .then(setData)
      .then(() => setLoading(false))
      .catch(console.error)

    return function cleanup () {
      controller.abort()
    }
  }, [currentRange])

  function formatTime (time) {
    return DateTime.fromSQL(time).toFormat('dd.MM.yyyy HH:mm:ss')
  }

  return (
    <div>
      <Navbar>
        <Navbar.Brand>Kartoffellager Zuhause</Navbar.Brand>
      </Navbar>

      <Container className="App">
        <Card>
          <Card.Header>
            <Card.Title>aktuelle Temperatur</Card.Title>
            <Card.Subtitle
              className="text-muted">{formatTime((data[data.length - 1] || {}).time)}</Card.Subtitle>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="d-flex align-items-center flex-column">
                <span className="text-muted">Innen</span>
                <h1>{(data[data.length - 1] || {}).inside} &deg;C</h1>
              </Col>
              <Col className="d-flex align-items-center flex-column">
                <span className="text-muted">Außen</span>
                <h1>{(data[data.length - 1] || {}).outside} &deg;C</h1>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Header
            className="d-flex justify-content-between align-items-baseline flex-column flex-md-row">
            <Card.Title>Temperaturverlauf</Card.Title>
            <ButtonGroup>
              <Button
                variant={currentRange === TimeRanges.LAST_DAY ? 'secondary' : 'outline-secondary'}
                onClick={() => setCurrentRange(TimeRanges.LAST_DAY)}>
                Letzte 24 Stunden
              </Button>
              <Button
                variant={currentRange === TimeRanges.LAST_SEVEN_DAYS ? 'secondary' : 'outline-secondary'}
                onClick={() => setCurrentRange(TimeRanges.LAST_SEVEN_DAYS)}>
                Letzte 7 Tage
              </Button>
              <Button
                variant={currentRange === TimeRanges.LAST_MONTH ? 'secondary' : 'outline-secondary'}
                onClick={() => setCurrentRange(TimeRanges.LAST_MONTH)}>
                Letzte 30 Tage
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            {loading
              ? <div className="d-flex justify-content-center">
                <Spinner animation="border"/>
              </div>
              : <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data}>
                  <Legend verticalAlign="top" height={36}/>
                  <Line type="monotone" dataKey="outside" name="Außen"
                        stroke="#8884d8" connectNulls={true} dot={false}/>
                  <Line type="monotone" dataKey="inside" name="Innen"
                        stroke="#aa2222" connectNulls={true} dot={false}/>
                  <CartesianGrid stroke="#ccc"/>
                  <YAxis unit=" &deg;C"/>
                  <XAxis dataKey="time" tick={CustomizedAxisTick} height={100}/>
                  <Tooltip/>
                </LineChart>
              </ResponsiveContainer>
            }
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Header>
            <Card.Title>
              Wettervorhersage
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex justify-content-center">
            <iframe marginHeight='0' marginWidth='0' scrolling='no' width='300'
                    height='365' name='FC3' title="weatheronline-forecast"
                    src='https://api.wetteronline.de/wetterwidget?gid=a8522&modeid=FC3&seourl=hebertshausen/ampermoching&locationname=Ampermoching&lang=de'/>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default App
